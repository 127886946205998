// MUI
import {
    Analytics as AnalysticsIcon,
    AttachMoney as AttachMoneyIcon,
    Calculate as CalculateIcon,
    Computer as ComputerIcon,
    List as ListIcon,
    ListAlt as ListAltIcon,
    People as PeopleIcon

} from '@mui/icons-material';
// Components
import {Item} from './NavSection';
// Store
import {
    auxReports,
    financeReports,
    investorReports, itReports,
    loanAdminReports,
    portfolioReports
} from "../../config/BIReportsConfig";

// ----------------------------------------------------------------------

const navConfig: Item[] = [
    {
        title: 'Home',
        path: '/reports/home',
        icon: <ListAltIcon/>,
    },
    {
        title: 'Portfolio Reports',
        path: '/reports/portfolio',
        icon: <AnalysticsIcon/>,
        children: portfolioReports.map(report => ({
            title: report.title,
            path: `/reports/portfolio/${report.id}`,
            icon: report.icon
        }))
    },
    {
        title: 'Financial Reports',
        path: '/reports/financial',
        icon: <CalculateIcon/>,
        children: financeReports.map(report => ({
            title: report.title,
            path: `/reports/financial/${report.id}`,
            icon: report.icon
        }))
    },
    {
        title: 'Investor Reports',
        path: '/reports/investor',
        icon: <PeopleIcon/>,
        children: investorReports.map(report => ({
            title: report.title,
            path: `/reports/investor/${report.id}`,
            icon: report.icon
        }))
    },
    {
        title: 'Loan Admin Reports',
        path: '/reports/loan-admin',
        icon: <AttachMoneyIcon/>,
        children: loanAdminReports.map(report => ({
            title: report.title,
            path: `/reports/loan-admin/${report.id}`,
            icon: report.icon
        }))
    },
    {
        title: 'IT Reports',
        path: '/reports/it',
        icon: <ComputerIcon/>,
        children: itReports.map(report => ({
            title: report.title,
            path: `/reports/it/${report.id}`,
            icon: report.icon
        }))
    },
    {
        title: 'Auxiliary Reports',
        path: '/reports/auxiliary',
        icon: <ListIcon/>,
        children: auxReports.map(report => ({
            title: report.title,
            path: `/reports/auxiliary/${report.id}`,
            icon: report.icon
        }))
    },
];

export default navConfig;
